import * as React from "react"
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  Heading,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  UnorderedList,
  ListItem,
  SimpleGrid,
  Stack,
  Divider,
} from "@chakra-ui/react"
import { PhoneIcon, AddIcon, WarningIcon, SunIcon } from '@chakra-ui/icons'

import { ColorModeSwitcher } from "./ColorModeSwitcher"
import { Logo } from "./Logo"
import Header from "./components/Header";
import TechnologyCard from "./components/TechnologieCard";
import Experience from "./components/Experience";
import Footer from "./components/Footer";

const techData = [
  { name: 'NodeJs', summary: 'Niveau Senior/ Depuis 2016', imageUrl: 'nodejs.png' },
  { name: 'NestJs', summary: 'Niveau Senior/ Depuis 2019', imageUrl: 'nestjs.png' },
  { name: 'Angular', summary: 'Niveau Senior/ Depuis 2016', imageUrl: 'angular.png' },
  { name: 'React', summary: 'Niveau Senior/ Depuis 2021', imageUrl: 'react.png' },
  { name: 'CSS/Html', summary: 'Niveau Senior/ Depuis 2015\nCertification HTML5', imageUrl: 'html.png' },
  { name: 'DevOps', summary: 'Niveau moyen', imageUrl: 'docker.png' },
];

export const App = () => (
  <ChakraProvider theme={theme}>
    <Header></Header>
    <Card style={{marginLeft:'20%', marginRight:'20%', marginTop: '20px' }}>
      <CardHeader>
        <Heading as='h2' size='2xl'>Technologies</Heading>
      </CardHeader>
      <CardBody>
      <SimpleGrid columns={4} spacing={10}>
        {techData.map((tech, index) => (
          <TechnologyCard key={index} name={tech.name} summary={tech.summary} imageUrl={tech.imageUrl} />
        ))}
      </SimpleGrid>
      </CardBody>
    </Card>
    <Card style={{marginLeft:'20%', marginRight:'20%', marginTop: '20px', marginBottom:'150px' }}>
      <CardHeader>
        <Heading as='h2' size='2xl'>Expériences profesionnelles</Heading>
      </CardHeader>
      <CardBody>
        <Experience></Experience>
        </CardBody>
    </Card>
    <Footer></Footer>
  </ChakraProvider>
)
