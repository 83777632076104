import React, { Component } from 'react'
import { Card, CardHeader, CardBody } from '@chakra-ui/card';
import { Heading, Text } from '@chakra-ui/react';

export class Header extends Component {
  render() {
    return (
        <Card style={{marginLeft:'20%', marginRight:'20%', marginTop: '150px' }}>
            <CardHeader>
                <Heading as='h2' size='2xl'>Ameni BEN MRAD</Heading>
            </CardHeader>
            <CardBody>
                <Text><b>Ingénieure Développeuse Web</b></Text>
                <Text>Fortement passionnée par les nouvelles technologies, je cumule une expérience de 7 ans en développement Fullstack javascript avec une expertise en NodeJs, NestJS, React, et Angular.</Text>
            </CardBody>
        </Card>
    )
  }
}

export default Header
