import React, { Component } from 'react'
import { List, ListItem, ListIcon } from '@chakra-ui/react';
import { SettingsIcon } from '@chakra-ui/icons';

export class Experience extends Component {
  render() {
    return (
      <div>
        <List spacing={3}>
          <ListItem>
            <SettingsIcon color='green.500' mr="4" />
            2022-2024: Hr team (homeserve) : Ingénieure développement fullstack NestJs/ReactJs<br />
            Refonte du backoffice dediée à la réparation à la demande en react et nestjs<br />
            Synchronisation du référentiel prestatire entre Salesforce et la plateforme de réparation à la demande.<br />
            Technologies: NodeJs, ReactJs, NestJs, TypeORM, Cucumber, Docker, Gitlab, Googlecloud
          </ListItem>
          <ListItem>
            <SettingsIcon color='green.500' mr="4" />
            2019-2022: Argalis : Lead développeur fullstack angular/NodeJs<br />
            Technologies: Angular 7, AngularJs, NodeJs, TypeORM, NestJs, Jest, Cypress, Amazon Web Service(aws), PostreSQL, Gitlab, Jenkins, Jira
          </ListItem>
          <ListItem>
            <SettingsIcon color='green.500' mr="4" />
            2018-2019: Argalis : Développeuse fullstack angular/NodeJs
          </ListItem>
          {/* You can also use custom icons from react-icons */}
          <ListItem>
            <SettingsIcon color='green.500' mr="4" />
            2018: SimplX: Développeuse fullstack angular/NodeJs/Ionic<br />
            Développement d'une application mobile permettant aux soudeurs de rails de soumettre leurs rapport d'intervention<br />
            Développement d'une plateforme web permettant de superviser les interventions des soudeurs<br />
            Technologies: Angular, NodeJS, Ionic, Bootstrap, Sequelize, Socket.io, Gitlab
          </ListItem>
        </List>
      </div>
    )
  }
}

export default Experience
