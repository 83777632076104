import React from 'react';
import { Card, CardBody } from '@chakra-ui/card';
import { Stack, Heading, Text, Flex, Image } from '@chakra-ui/react';

interface TechnologyCardProps {
  name: string;
  summary: string;
  imageUrl: string;
}

const TechnologyCard: React.FC<TechnologyCardProps> = ({ name, summary, imageUrl }) => {
  return (
    <Card maxW='sm'>
    <CardBody>
        <Flex direction="column" alignItems="center" textAlign="center">
        <Image src={'/images/' + imageUrl} alt={name} style={{ width: 'auto', height: '50px' }} />
        <Heading size='md'>{name}</Heading>
        <Text style={{ marginTop: '10px' }}>{summary}</Text>
        </Flex>
    </CardBody>
    </Card>
  );
};

export default TechnologyCard;