import React from 'react';
import { Box, Flex, Text, Link, List, ListItem, ListIcon, SimpleGrid, Divider } from '@chakra-ui/react';
import { EmailIcon, PhoneIcon, Search2Icon } from '@chakra-ui/icons';

const Footer = () => {
  return (
    <Box p={8} bg="gray.800" color="white">
    {/* ... Votre contenu d'expérience ... */}
  <Flex justify="center" mt={8}>
    <SimpleGrid columns={{ base: 1, sm: 1, md: 3 }} spacing={8}>
      <Box textAlign="center">
        <EmailIcon color="green.500" />
        <Text>Email: amenibenmrad@gmail.com</Text>
      </Box>
      <Box textAlign="center">
        <PhoneIcon color="green.500" />
        <Text>Téléphone: (+33) 699989547</Text>
      </Box>
      <Box textAlign="center">
        <Search2Icon color="green.500" />
        <Text>Localisation: Lyon, France</Text>
      </Box>
    </SimpleGrid>
  </Flex>
  <Divider mt={10} />

    <Flex justify="center" mt={6}>
      <Text fontSize="sm" textAlign="center">
        © 2023 Ameni Ben Mrad. Tous droits réservés.
      </Text>
    </Flex>
  </Box>
  );
};

export default Footer;